.ant-space  {
    margin-right: 4px;
}

.ant-space-item {
    input {
        width: 80px !important;
        font-size: 12px !important;
        text-align: center;
    }
    .ant-picker-range-separator {
        padding: 0  2px;
    }
}

.datepicker_footer {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;

    p {
        margin: 0;
    }

    span {
        margin-left: 6px;
        padding: 2px 5px;
        border-radius: 5px;
        cursor: pointer;
        color: white;
    }

    .c {
        border: 1px solid #ff5417b5;
        background-color: #ff5417b5;
        &:hover {
            background-color: #99401fb5;
        }

    }

    .ok {
        border: 1px solid #19c37d;
        background-color: #19c37d;

        &:hover {
            background-color: #15794f;
        }
    }
}