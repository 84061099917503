@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i");

@import "normalize.css";

/* src/styles.css */
@font-face {
  font-family: "tiklite";
  src: url("./fonts/TikTokDisplay-Bold.otf") format("opentype"),
    url("./fonts/TikTokDisplay-Medium.otf") format("opentype"),
    url("./fonts/TikTokDisplay-Regular.otf") format("opentype"),
    url("./fonts/TikTokText-Bold.otf") format("opentype"),
    url("./fonts/TikTokText-Medium.otf") format("opentype"),
    url("./fonts/TikTokText-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

::root {
  --while-color: #fff;
  --black-color: #000;
  --text-color: #333;
  --primary: #ff0050;
  --aqua-color: #00f2ea;
}

* {
  box-sizing: inherit;
}

html {
  font-family: "Open Sans", sans-serif !important;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  font-size: 1.2rem;
}

input {
  border: none;
  outline: none;
}

.tab-content .content::-webkit-scrollbar-track {
  cursor: pointer;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}
.tab-content .content::-webkit-scrollbar {
  cursor: pointer;
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}
.tab-content .content::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgba(30, 31, 31, 0.449))
  );
}

table {
  th,
  td {
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}
