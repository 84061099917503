.ant-tooltip-inner {
    font-size: 10px !important;
}

.ant-table-wrapper {
    height: 100%;

    .ant-spin-nested-loading {
        height: 100%;

        .ant-spin-container {
            height: 100%;

            .ant-table-content {
                overflow: visible !important;

                table {
                    border-collapse: collapse;
                    // table-layout: fixed !important;
                }

                .ant-table-measure-row {
                    display: none;
                }

                thead {
                    z-index: 1;
                }

                th {
                    background-color: #fffb008e;
                    min-width: 60px !important;
                    color: #ff0050;
                    font-weight: bold;

                    .ant-dropdown-trigger {
                        margin: 0;
                        margin-left: 2px;
                        padding: 0;
                    }

                    .anticon {
                        font-size: 9px;
                    }

                    .anticon-caret-down {
                        margin-top: -0.5em;

                    }

                    .anticon-caret-up {}
                }

                .ant-table-cell {
                    // position: static !important;
                }

                td,
                th {
                    padding: 0px !important;
                    border: 1px solid #bac8d8;
                    font-size: 12.6px;
                }

                td {
                    color: #000000;
                    padding: 3px !important;
                    overflow: visible;
                }
            }
        }
    }

    .ant-table {
        height: 100%;
        /* Đảm bảo chiều cao của bảng luôn bằng với chiều cao của div */

    }


    .ant-pagination {
        width: calc(100% - 6px);
        position: fixed;
        gap: 5px;
        bottom: 43px;
        margin: 0 !important;
        padding: 1px 10px;
        background-color: white;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        .ant-pagination-item {

            color: #ff0050;
        }

        .ant-pagination-item-active {
            background-color: #ff00774b;
            color: white;
            border: none;
        }

        li {
            min-width: 18px;
            margin: 0;
            line-height: 18px;
            font-size: 12px;
            a {
                font-size: 12px;
                color: #ff0050;
            }
            button {
                display: flex !important;
                color: #ff0050 !important;
                align-items: center !important;
                justify-content: center !important;
            }

            span {
                font-size: 12px !important;
                height: 18px;
                color: #ff0050 !important;
                line-height: 18px;
            }
        }
    }

    .ant-table-summary {
        z-index: 0;
    }

}