html {
    background-color: black;
}

.navbar {
    padding: 5px 50px;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
        img {
            width: 100px;
        }
    }
}

.footer_landing {
    width: 100%;
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-around;

    ul {
        background-color: black;
    }

    a {
        color: white;
    }
}

.button {
    color: white;
    background-color: #ff0050;
    border-radius: 5px;
    border: none;
    outline: none;
    padding: 10px;
    cursor: pointer;
}

button {
    color: white;
    background-color: #ff0050;
    border-radius: 5px;
    border: none;
    outline: none;
    padding: 10px;
    cursor: pointer;
}

.privacy {
    margin: auto;
    max-width: 1200px;
    color: white;
}

.lading-content {
    width: 100%;
    margin-top: 52px auto 0 auto;
    font-size: 100% !important;
    section {
        margin: auto;
    }

    .one {
        background-color: black;
        padding: 50px;

        .one-content {
            color: white;
            margin: auto;
            max-width: 1200px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        h1 {
            color: white;
            font-size: 44px;
        }

        .text-one {
            flex: 1;
        }

        .img-one {
            flex: 1;

            img {
                width: 100%;
                border-radius: 10px;
            }
        }
    }
}