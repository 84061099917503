

.main-app {
  font-size: 12.6px;
  background-color: black;
  padding: 0 3px;
}


.containers {
  height: calc(100vh - 94px);
  min-height: 450px;
  background-color: white;
  padding: 0px;
  border-radius: 10px;
  overflow: hidden;
  font-size: 12.6px;


  .tool-bar-error {
    width: 100%;
    display: flex;
    font-weight: 500;
    background-color: #ff0050;

    padding: 5px 5px;
    align-items: center;
    justify-content: flex-start;
    color: white;

    .icon {
      margin-right: 10px;
    }

    .link-text {
      text-transform: capitalize;
    }

    .right {
      display: flex;
      align-items: center;
      margin-right: 15px;

      .btn {
        user-select: none;
        height: 25px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #b9f3fa46;
        border-radius: 5px;
        margin: 0 3px;
        cursor: pointer;
        color: #fff;

        &:hover {
          background-color: #b9f3fa33;
        }

        span {
          font-size: 12.6px;
        }

        i {
          font-size: 14px;
        }
      }
    }

    .left {
      display: flex;
      align-items: center;
      justify-content: center;

      .tab-list {
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        overflow: hidden;

        .tabs {
          padding: 2px 10px;
          cursor: pointer;
          background-color: white;
          color: #0e0e0e;

          &:hover {
            background-color: rgb(36 131 148);
            color: white;
          }
        }
      }

      .active-tab {
        padding: 2px 10px;
        background-color: rgb(36 131 148);
        color: white;
      }
    }
  }
}



header {
  color: white;
  min-width: 500px;
  height: 52px;

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;

    .menu {
      font-size: 20px;
      color: white;
    }

    .app-logo {
      margin-left: 40px;

      img {
        width: 100px;
      }

    }

    .bcinfo {
      background-color: rgba(255, 255, 255, 0.24);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1px 10px;
      border-radius: 5px;
      height: 25px;
      gap: 5px;

      img {
        border-radius: 50%;
        height: 20px;
        width: 20px;
      }

      i {
        margin-left: 10px;
        cursor: pointer;

        &:hover {
          color: red;
        }
      }
    }

  }
}

footer {
  align-items: center;
  color: #fff;
  display: flex;
  height: 40px;
  justify-content: space-between;
  padding: 0 4px;

  .footer-left {
    color: rgb(0, 149, 255);
  }

  p {
    margin: 0;
  }
}

.tool-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #ff0050;
  padding: 3px 0px;
  font-size: 12.6px;

  .icon {
    margin-left: 10px;
    color: white;
    font-weight: 600;

    i {
      margin-right: 10px;
    }
  }

  .link-text {
    text-transform: capitalize;
  }

  .left {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    .tab-list {
      display: flex;
      margin-left: 5px;
      border-radius: 5px;
      overflow: hidden;
      margin-right: 10px;

      .tab {
        cursor: pointer;
        padding: 0 10px;
        background-color: white;
        height: 100%;
        padding: 4px;
        font-weight: 600;
      }

      .active-tab {
        background-color: #00f2ea;
        color: ff0050;

      }
    }

    .search {
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      border: none;

      input {
        font-size: 12.6px;
        padding: 3px;
        padding-left: 15px;
      }

      i {
        position: absolute;
        right: 10px;
        top: 5px;
      }
    }
  }

  .right {
    display: flex;

    .btn {
      cursor: pointer;
      color: white;
      border-radius: 2px;
      margin-right: 10px;
      padding: 4px 8px;
      background-color: rgba(255, 255, 255, 0.26);
    }
  }
}

.tab-content {
  height: calc(100% - 55px);
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  .install {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    button {
      padding: 10px 15px;
      border-radius: 8px;
      border: none;
      background-color: #ff0050;
      color: white;
      font-weight: 500px;
      cursor: pointer;

      &:hover {
        background-color: #005467;
      }
    }
  }

  .content::-webkit-scrollbar-track {
    cursor: pointer;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  .content::-webkit-scrollbar {
    cursor: pointer;
    width: 8px;
    height: 8px;
    background-color: #f5f5f5;
  }

  .content::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 10px;
    background-image: -webkit-gradient(linear,
        left bottom,
        left top,
        color-stop(0.44, rgba(30, 31, 31, 0.449)));
  }

  .content {
    display: none;
  }

  .active-content {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: block;
  }

  table {
    width: 100%;
    white-space: nowrap;
    border: 1px solid;
    border-collapse: collapse;

    th {
      user-select: none;
    }

    td,
    th {
      text-align: center;
      border: 1px solid #bac8d8;
      padding: 0 3px;
      text-transform: capitalize;

      .avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        img {
          width: 40px;
          border-radius: 20px;
        }
      }
    }

    td {
      padding: 3px;

      div {
        padding: 0;
      }

      .status {
        border-radius: 5px;
        box-shadow: 0px 1px 2px #00000027;

      }

      .g-status {
        background-color: rgba(84, 244, 166, 0.289);
        color: rgb(7, 161, 7);
      }

      .r-status {
        background-color: rgba(255, 0, 0, 0.135);
        color: red;
      }

      .y-status {
        background-color: rgba(255, 255, 0, 0.348);
        color: rgb(204, 134, 4);
      }

      .p-status {
        background-color: rgba(226, 77, 226, 0.218);
        color: rgb(240, 5, 221);
      }

      .d-status {
        background-color: rgba(255, 255, 0, 0.348);
        color: rgb(170, 112, 3);
      }
    }

    thead {
      position: sticky;
      top: -1px;
      background-color: bisque;
    }
  }
}